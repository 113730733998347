import { Link } from "gatsby";
import { ArrowRight } from "lucide-react";
import React from "react";

import * as styles from "./BetaNotice.module.scss";

const BetaNotice = () => {
    return (
        <div className={styles.betaNotice}>
            <div className={styles.underConstruction}></div>
            <div className={styles.betaNoticeContent}>
                <span
                    className={styles.betaNoticeHeading}
                    role="heading"
                    aria-level="1"
                >
                    CLOSED BETA
                </span>
                <p>
                    MinervaTools is currently in closed beta so we can tweak
                    things to be just right in close collaboration with our
                    early adopters so it will be perfect for you!
                </p>

                <Link className={styles.buttonLight} to={"/beta"}>
                    Learn more about the beta <ArrowRight />
                </Link>
            </div>
            <div className={styles.underConstruction}></div>
        </div>
    );
};

export default BetaNotice;
