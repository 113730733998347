// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---content-pages-beta-mdx": () => import("./../../../content/pages/beta.mdx" /* webpackChunkName: "component---content-pages-beta-mdx" */),
  "component---content-pages-features-mdx": () => import("./../../../content/pages/features.mdx" /* webpackChunkName: "component---content-pages-features-mdx" */),
  "component---content-pages-legal-imprint-mdx": () => import("./../../../content/pages/legal/imprint.mdx" /* webpackChunkName: "component---content-pages-legal-imprint-mdx" */),
  "component---content-pages-legal-privacy-mdx": () => import("./../../../content/pages/legal/privacy.mdx" /* webpackChunkName: "component---content-pages-legal-privacy-mdx" */),
  "component---content-pages-legal-tos-mdx": () => import("./../../../content/pages/legal/tos.mdx" /* webpackChunkName: "component---content-pages-legal-tos-mdx" */),
  "component---content-pages-pricing-mdx": () => import("./../../../content/pages/pricing.mdx" /* webpackChunkName: "component---content-pages-pricing-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

